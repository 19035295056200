#confirmSenha, #novaSenha {
    height: 50px;
    width: 70%;
}

.email {
    position: relative;
    left: 5px;
    width: 100%;
}

.tamanho {
    width: 100%;
    height: 120px;
}
