.box {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 25px 30px !important;
  margin-bottom: 30px !important;
  text-align: left !important;
}

.primeiroAcesso {
  background: #909090 !important;
  color: white !important;
  width: 100% !important;
  border-color: transparent !important;
  height: 35px !important;
}

.primeiroAcesso:hover {
  background-color: #707070;
  color: white;
  border-color: transparent !important;
  height: 35px !important;
}

.esqueciSenha{
  text-align: center !important;
}