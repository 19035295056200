.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0;
}

.cardProcedimentos {
  margin-bottom: 5px !important;
  background-color: #ffffff !important;
  border: 1px solid #b3b3b3 !important;
  height: 75px !important;
  cursor: pointer !important;
  .ant-card-body {
    height: 75px !important;
    padding: 8px !important;
  }
}

.outroConvenio {
  border-color: #909090 !important;
  color: #ffffff !important;
  font-size: 10px !important;
  width: fit-content !important;
  height: fit-content !important;
  margin-top: -15px !important;
}

.outroConvenio:hover {
  border-color: #808080 !important;
  color: #ffffff !important;
  font-size: 10px !important;
  width: fit-content !important;
  height: fit-content !important;
}

.tituloFormaAgendamento {
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 6px !important;
}
