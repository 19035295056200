.botaoPrimario {
  color: white !important;
  width: 100% !important;
  border-color: transparent !important;
  border-radius: 8px !important;
  height: 35px !important;
  margin-bottom: 16px !important;
}

.botaoPrimario:hover {
  color: white !important;
  width: 100% !important;
  border-color: transparent !important;
  border-radius: 8px !important;
  height: 35px !important;
  margin-bottom: 16px !important;
}
