@import "./App.scss";

.body {
  background-color: #f0f0f0 !important;
  font-family: $font-primary !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//inicio antd
.ant-typography {
  font-family: $font-primary !important;
}

// .buttonPrimary {
//   .ant-btn {
//     font-family: $font-primary !important;
//     font-size: 12px !important;
//     border-radius: 8px !important;
//     height: 40px !important;
//   }

//   .ant-btn:hover {
//     font-family: $font-primary !important;
//     font-size: 12px !important;
//     border-radius: 8px !important;
//     height: 40px !important;
//     border: transparent !important;
//   }
// }

.ant-input,
.ant-input-password {
  font-size: 16px !important;
  border-radius: 8px !important;
}

.ant-form-item-label,
.ant-select-selection-placeholder {
  font-family: $font-primary !important;
}

.ant-radio-button-wrapper {
  border-inline-start: 1px solid #d9d9d9 !important;
  border-start-start-radius: 8px !important;
  border-end-start-radius: 8px !important;
  border-start-end-radius: 8px !important;
  border-end-end-radius: 8px !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

//inicio segmented
.ant-segmented {
  padding: 0px !important;
  background-color: #f0f0f0 !important;
  border-radius: 0px !important;
  color: #000000 !important;
}

.ant-segmented-item {
  font-family: $font-primary !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 8px !important;
  margin-right: 6px !important;
  padding-inline: 15px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  height: 32px !important;
}

.ant-segmented-item-selected,
.ant-segmented-item-selected:hover {
  font-family: $font-primary !important;
  background-color: #909090 !important;
  color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
}

//fim segmented

.ant-radio-button-wrapper {
  font-family: $font-primary !important;
}

.ant-radio-button-wrapper:hover {
  font-family: $font-primary !important;
  color: #000000;
}

.ant-radio-button-checked,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  font-family: $font-primary !important;
  background-color: #909090 !important;
  border-color: #909090 !important;
  border-radius: 8px !important;
}

.ant-modal-header {
  text-align: center !important;
  border-bottom-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  height: 40px !important;
  font-size: 11px !important;
  font-family: $font-primary !important;
}

.ant-card-body {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  padding: 0px !important;
  width: 100% !important;
}

.inputNumberClass {
  .ant-input-number .ant-input-number-input {
    text-align: end;
  }
}
//fim antd

//inicio row da flecha com os textos e carrinho
.titulo {
  font-size: 15px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 6px !important;
}

.tituloAnexos {
  font-size: 18px !important;
  font-weight: bold !important;
  width: 100% !important;
  margin-top: 8px !important;
}

.subtitulo {
  // color: #505050 !important;
  font-size: 13px !important;
  margin-left: 23px !important;
  margin-top: -23px !important;
}

.subtituloAnexos {
  color: #505050 !important;
  font-size: 13px !important;
  text-align: left !important;
  text-align: justify !important;
  margin-left: 23px !important;
  margin-top: -15px !important;
}

.flechaCol {
  flex: none !important;
  margin-right: 8px !important;
}

.tituloCol {
  margin-right: 11px !important;
}
//fim row da flecha com os textos e carrinho

.botaoPrimario {
  background-color: var(--color-variable);
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
select:focus,
textarea:focus {
  font-size: 16px !important;
}

.ant-input:focus {
  font-size: 16px !important;
}

.masked,
.masked:focus {
  font-size: 16px !important;
}
