.iconShopCarrinho {
  font-size: 30px !important;
  color: #909090 !important;
}

.ant-badge .ant-badge-count {
  background: #909090 !important;
  border-color: #909090 !important;
  box-shadow: none !important;
}
