.fullSchedule {
  margin-left: 0;
  width: 100%;
  .examSelected {
    color: #909090;
    font-weight: 400;
    font-size: 18px;
  }
  .sepDashed {
    margin-bottom: 3%;
  }
}

.titleListSchedule {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3%;
}

.deleteIcon {
  .anticon svg {
    width: 40px !important;
    height: 40px !important;
  }
}

.listDivider {
  margin: 0px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}