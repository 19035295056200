@keyframes swipe {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(20px);
    }
    50% {
      transform: translateX(0);
    }
    75% {
      transform: translateX(-20px);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .swipe-animation {
    animation: swipe 2s linear infinite;
  }