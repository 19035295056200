.subtituloAnexosObrigatorios {
  color: #505050 !important;
  font-size: 13px !important;
  text-align: left !important;
  text-align: center !important;
}

.botaoAvancarObrigatorios {
  max-width: 480px !important;
  width: 100% !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 40px !important;
  margin-top: auto !important;
}
