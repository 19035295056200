.ant-steps-item-process {
  .ant-steps-item-icon {
    background-color: #909090 !important;
    border-color: #909090 !important;
  }
  .ant-steps-icon {
    color: #ffffff !important;
  }
}

.ant-steps-item-icon {
  background-color: #ffffff !important;
  border-color: #909090 !important;
  margin-inline-start: 15px !important;
}

.ant-steps-item-content {
  width: 60px !important;
}

.ant-steps-item-tail {
  margin-inline-start: 26px !important;
}

.ant-steps-icon {
  color: #909090 !important;
}

.ant-steps .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #909090 !important;
}

.ant-progress-circle-path {
  stroke: #909090 !important;
}
