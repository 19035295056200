.cardUnidade {
  background-color: #ffffff !important;
  border: 1px solid #b3b3b3 !important;
  height: fit-content !important;
  cursor: pointer !important;
  .ant-card-body {
    height: fit-content !important;
    padding: 8px !important;
  }
}

.comoChegar {
  font-size: 12px !important;
}
