.header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  color: white !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
  max-height: 80px !important;
  min-height: 80px !important;
  padding: 0 20px !important;
}

.voltar {
  cursor: pointer;
}

.iconMenuHeader {
  cursor: pointer !important;
  font-size: 25px !important;
}

.iconLeftHeader {
  cursor: pointer !important;
  font-size: 25px !important;
}

.hiddenVisibility {
  visibility: hidden !important;
}


.ant-layout-header {
  line-height: 0 !important;
}