@import "../../../App.scss";

.cardDependentes {
  border: 1px solid #b3b3b3 !important;
  height: 50px !important;
  cursor: pointer !important;
  .ant-card-body {
    height: 50px !important;
    padding: 8px !important;
  }
}

.cardMeusDependentes {
  border: 1px solid #b3b3b3 !important;
  height: 70px !important;
  cursor: pointer !important;
  .ant-card-body {
    height: 70px !important;
    padding: 8px !important;
  }
}

.buttonActionDependentes {
  width: fit-content !important;
  background-color: transparent !important;
  border-color: #b3b3b3 !important;
  font-family: $font-primary !important;
}

.buttonActionDependentes:hover {
  width: fit-content !important;
  background-color: transparent !important;
  border-color: #b3b3b3 !important;
  font-family: $font-primary !important;
  color: #000000 !important;
}
