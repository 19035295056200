.cadastroInicial {
  .select select {
    width: 100%;
    height: 3.2em;
    border-radius: 4px;
  }

  .select {
    width: 100%;
    height: 3.2em;
  }

  input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

}

.titleRegister.title.is-5 {
  display: flex;
  color: #303030;
  margin-left: 76px;
  margin-bottom: 0px;
}

.titleRegisterMiddle.title.is-5 {
  display: flex;
  color: #303030;
  margin-left: 62px;
  margin-bottom: 0px;
}

.labelFlex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.selectField {
  display: flex;
  width: 200px;
  flex-direction: column;
  justify-content: left;
  border-radius: 10px;
}

.input {
  border-radius: 8px;
  padding: 25px 20px;
  border-color: #e5e5e5 !important;
}

.selectField.plan {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: left;
  border-radius: 10px;
}

.fieldCheck {
  .checkbox input {
    margin-right: 5px;
  }
  span {
    margin: 0 3px 0 3px;
    color: #006245;
    font-weight: 500;
    cursor: pointer;
  }
}

.containerHorario.containerCadastro {
  margin-top: 4%;
}
