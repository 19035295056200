.noOption {
  background: #505050 !important;
  color: #ffffff !important;
  width: 100% !important;
}

.noOption:hover {
  background-color: #505050 !important;
  color: #ffffff !important;
}

.enviarCodigo {
  .ant-radio-group {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    justify-content: center !important;
  }
}
