@import "../../../../App.scss";

.menuBurguer {
  .ant-menu {
    font-size: 14px !important;
    color: #ffffff !important;
    text-align: left !important;
    font-family: $font-primary !important;
    height: 100% !important;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover{
    background-color: #ffffff !important;
  }
  .ant-menu-light .ant-menu-item-selected{
    color: #000000 !important;
  }
    height: 100% !important;
}

.headerBurguer {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  color: white !important;
  max-height: 80px !important;
  min-height: 80px !important;
  padding: 0 20px !important;
}

.iconCloseMenuBurguer {
  cursor: pointer !important;
  font-size: 32px !important;
  color: #ffffff !important;
}

.barraBranca {
  align-items: center;
  border-radius: 10px;
  background: #ffffff;
  height: 5px;
  border-color: #ffffff;
  margin-top: 15px;
}
