.cardPacientes {
  border: 1px solid #b3b3b3 !important;
  height: 70px !important;
  cursor: pointer !important;
  .ant-card-body {
    height: 70px !important;
    padding: 8px !important;
  }
}

.tituloPacientes {
  font-size: 15px !important;
  font-weight: bold !important;
  margin-top: 6px !important;
}
