.botaoBuscarHorarios {
  max-width: 480px !important;
  width: 90% !important;
  color: #ffffff !important;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  bottom: 5px !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.botaoAdicionarHorario {
  max-width: 480px !important;
  width: 90% !important;
  color: #ffffff !important;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  bottom: 65px !important;
  border-radius: 8px !important;
  height: 40px !important;
}

.botaoAdicionarHorario:hover {
  max-width: 480px !important;
  width: 90% !important;
  color: #ffffff !important;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  bottom: 65px !important;
  border-radius: 8px !important;
  height: 40px !important;
}


.divisor {
  max-width: 480px !important;
  width: 90% !important;
  background-color: #d9d9d9;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  bottom: 55px !important;
  height: 1px !important;
}

