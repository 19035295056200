.cardMedicos {
  background-color: #ffffff !important;
  border: 1px solid #b3b3b3 !important;
  height: 50px !important;
  cursor: pointer !important;
  .ant-card-body {
    height: 50px !important;
    padding: 8px !important;
  }
}
