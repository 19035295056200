.containerHorario.containerPerfil {
  bottom: 70px;
}

.divContainer.containerP {
  bottom: 35px;
}

.secDepPerfil {
  padding: 0;
  margin-right: 30px;
  .titleDep {
    width: fit-content;
    bottom: 70px;
    position: relative;
    margin-left: 8%;
  }

  .linkCadDep {
    width: fit-content;
    position: relative;
    margin-left: 8%;
    bottom: 40px;
    text-decoration: underline;
    cursor: pointer;
  }

  .iconArrow {
    height: 80px;
  }
}

.listaTelefones {
  .ant-list-items {
    display: flex !important;
    flex-direction: row !important;
    gap: 16px !important;
  }
  .ant-list-split .ant-list-item {
    border-block-end: none !important;
  }
}
