.boxHorarios {
  background-color: white !important;
  border-radius: 10px !important;
  padding: 25px 30px !important;
  margin-bottom: 30px !important;
}

.formHorarios {
  .ant-form-item .ant-form-item-label > label {
    display: flex !important;
    width: 100% !important;
  }
}

.segmentedDias {
  .ant-segmented-item {
    width: fit-content !important;
    padding-inline: 0px !important;
  }
}
