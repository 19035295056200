.listaExames {
  .ant-list-item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
  }
  .ant-list-item-meta {
    height: 70px !important;
    align-items: center !important;
  }
  .ant-list-item-meta-content {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: flex-start !important;
    text-align: left !important;
  }
  .ant-collapse-header {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    color: #ffffff !important;
    background-color: #909090 !important;
    height: 20px !important;
    text-align: left !important;
  }
}
