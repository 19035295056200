.sd-root-modern {
  background-color: #f0f0f0 !important;
}

.sd-header__text .sd-title,
.sd-root-modern .sd-container-modern__title .sd-description {
  color: #000000 !important;
}

.sd-input {
  box-shadow: none !important;
  background-color: #ffffff !important;
}

.sd-item__decorator {
  box-shadow: none !important;
  cursor: pointer !important;
}

.sd-title.sd-container-modern__title {
  box-shadow: 0px 1px 0px var(--sjs-primary-backcolor, var(--primary, #000000)) !important;
}

.sd-element__title .sd-element__num {
  font-size: 16px !important;
  color: #000000 !important;
}
