.app {
  display: flex;
  text-align: center;
  background-color: #f0f0f0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: 700px;
  flex-direction: column;
  flex: 1;
}
.title {
  color: #303030;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
}
// .subtitulo {
//   display: flex;
//   text-align: left;
//   justify-content: center;
//   margin-left: 10px;
// }


// form {
//   padding: 15px;
// }