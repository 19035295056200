.pdfDocument {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.pageButtons {
  margin-bottom: 14px;
  .anticon {
    color: #404040 !important;
  }
}

.botaoConcluirP{
  width: 100% !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 40px !important;
}