@import "../../../App.scss";

.cardOption {
  min-height: 80px !important;
  color: white !important;
  width: 100% !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  font-family: $font-primary !important;
  padding: 10px !important;
}
