@import "../../../../App.scss";

figure.image.imgScheduleDone {
  width: 100%;
  margin: 0;
}

.divImage {
  margin-top: 5%;
}

.examFinal {
  bottom: 95px;
}

.subtituloFinalizacao {
  color: #909090 !important;
  font-size: 13px !important;
  margin-left: 30px !important;
  margin-top: -15px !important;
}

// .carteirinha {
//   .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container {
//     width: 100% !important;
//     height: 150px !important;
//   }
//   .uploadButton {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 150px;
//     text-align: center;
//     justify-content: center;
//     border: 1px solid #e7e5e4;
//     cursor: pointer;
//     background-color: #ffffff;
//     border-radius: 10px;
//   }

//   .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
//     width: 100% !important;
//     height: 150px !important;
//   }
// }

// .guia {
//   .uploadButton {
//     display: flex;
//     flex-direction: column;
//     width: 104px;
//     height: 104px;
//     text-align: center;
//     justify-content: center;
//     border: 1px solid #e7e5e4;
//     cursor: pointer;
//     background-color: #ffffff;
//     border-radius: 10px;
//   }
// }

.uploadButton {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 104px;
  text-align: center;
  justify-content: center;
  border: 1px solid #e7e5e4;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 10px;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-container{
  width: 140px !important;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action.ant-btn-sm {
  margin-left: 20px !important;
}