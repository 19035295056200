.resumoPaciente{
  .ant-form-item-control{
    margin-top: -15px !important;
  }
}

.botaoConcluir {
  max-width: 480px !important;
  width: 90% !important;
  color: #ffffff !important;
  position: fixed !important;
  right: 0 !important;
  left: 0 !important;
  margin: 0 auto;
  bottom: 10px !important;
  border-radius: 8px !important;
  height: 40px !important;
}
