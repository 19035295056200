.digitPhone {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 100%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.controlInp {
    display: flex;
    //flex-direction: row;
    justify-content: center;
}

.svg-inline--fa.fa-paper-plane {
    color: white;
    padding-bottom:15px;
    padding-top:15px;
    width: 70px;
    height: 20px;
    background-color:  #505050;
    margin-right: -5px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    position: relative;
    z-index: 2;
    //border-radius: 8px;
    margin-top: 1px;
}

.cel{
    width: 83%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: center;
}

.rectify {
    background: #505050;
    color: white;
    width: 100%;
    margin-right: 18px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 25px 0;
  }
  
  .rectify:hover {
    background-color: #505050;
    color: white;
  }
