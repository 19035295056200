.layoutPrincipal {
  position: absolute !important;
  margin: 0 auto !important;
  left: 0 !important;
  right: 0 !important;
  max-width: 500px !important;
  overflow: unset !important;
  height: 100dvh !important;
}

.LayoutSecundario {
  position: relative !important;
  height: 100% !important;
  overflow: unset !important;
}

.conteudo {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
  padding: 0 24px !important;
  overflow: unset !important;
  height: calc(100% - 100px) !important;
}

.visibilityHidden {
  display: none !important;
}

.ant-layout {
  min-height: unset !important;
}
